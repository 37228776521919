<template>
	<div class="dialog">
		<el-dialog :title="typeFlag =='add' ?'添加':'修改'" :visible.sync="dialogVisible" width="700px" :before-close="close" :close-on-click-modal='false'>
			<div v-if="typeFlag=='score' || typeFlag=='recWord'">
				<template v-if="typeFlag=='score'">
					<!-- <div> -->
						<el-select size="small" v-model="value" placeholder="请选择" style="margin: 0 10px 10px 0">
							<el-option v-for="item in cities" :key="item.value" :label="item.label" :value="item.value">
								<span style="float: left">{{ item.label }}</span>
								<span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
							</el-option>
						</el-select>
						<el-input size="small" v-model="scoreNum" placeholder="请输入额度" class="input"></el-input>
					<!-- </div> -->
					<el-input size="small" v-model="desp" placeholder="请输入备注" class="input"></el-input>
				</template>
				<el-input size="small" v-model="rec_Word" placeholder="四字以内" class="input" maxlength='4' v-if="typeFlag=='recWord'"></el-input>
				<el-popconfirm confirm-button-text='是的' cancel-button-text='我再想想' icon="el-icon-info" icon-color="red" title="确定要修改吗？"
				@confirm='sure()'>
					<el-button slot="reference" type="primary">确定</el-button>
				</el-popconfirm>
			</div>
			<div v-else>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="机构logo" prop="logo">
						<Qiniu :isCropper="true" :width="250" :height="250" :imgKey="ruleForm.logo" @uploadSuccess="uploadSuccess"></Qiniu>
					</el-form-item>
					<el-form-item label="大门照片">
						<!-- prop="gate" -->
						<Qiniu :isCropper="true" :width="525" :height="420" :imgKey="ruleForm.gate" @uploadSuccess="uploadSuccess2"></Qiniu>
					</el-form-item>
					<el-form-item label="名字" prop="org_name">
						<el-input size="small" v-model="ruleForm.org_name" clearable></el-input>
					</el-form-item>
					<el-form-item label="营业时间">
						<el-time-picker size="small" is-range v-model="start_end_time" range-separator="至" start-placeholder="开始时间"
						end-placeholder="结束时间" placeholder="选择时间范围">
						</el-time-picker>
					</el-form-item>
					<baidu-map style="height: 500px; margin: 0 50px 24px" :center="map_center" :zoom="zoom" @ready="handler" @click="getClickInfo">
						<bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT" @changeAfter="changeAfter"></bm-city-list>
						<bm-view style="width: 100%; height: 100%; flex: 1"></bm-view>
						<bm-control>
							<bm-auto-complete v-model="ruleForm.address" :sugStyle="{ zIndex: 999999 }">
								<input type="text" placeholder="请输入搜索关键字" style="padding: 4px 10px;border-radius: 4px;border: solid 1px #ddd;margin: 10px 0 0 82px;width: 200px;" />
							</bm-auto-complete>
						</bm-control>
						<bm-local-search :keyword="ruleForm.address" :auto-viewport="true" style="width: 0px; height: 0px; overflow: hidden"></bm-local-search>
					</baidu-map>
					<el-form-item label="经纬度">
						<el-input size="small" :value="ruleForm.org_lng + ',' + ruleForm.org_lat" clearable></el-input>
					</el-form-item>
					<el-form-item label="电话" prop="org_phone">
						<el-input size="small" v-model="ruleForm.org_phone" clearable></el-input>
					</el-form-item>
					<!-- <el-form-item label="抖音地址">
						<el-input size="small" v-model="ruleForm.douyin_url"></el-input>
					</el-form-item> -->
					<el-form-item label="地址" prop="address">
						<el-input size="small" v-model="ruleForm.address" clearable></el-input>
					</el-form-item>
					<el-form-item label="详细地址" prop="detail">
						<el-input size="small" v-model="ruleForm.detail" clearable></el-input>
					</el-form-item>
					<el-form-item label="品牌口号：">
						<el-input size="small" v-model="ruleForm.org_desp" maxlength="15" clearable></el-input>
					</el-form-item>
					<el-form-item label="分类" prop="cates">
						<el-checkbox-group v-model="cates" @change="getTagsList">
							<el-checkbox v-for="item in catesList" :key="item.id" :label="item.id">{{ item.name }}</el-checkbox>
						</el-checkbox-group>
					</el-form-item>
					<el-form-item label="标签">
						<el-checkbox-group v-model="tags" :max="10">
							<el-checkbox v-for="item in tagsList" :key="item.tag_id" :label="item.tag_name">{{ item.tag_name }}</el-checkbox>
						</el-checkbox-group>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
						<el-button @click="close()">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import { saveOrg, orgInfo, score, recWord } from '@/api/institutional'
	import { allCate, getTags } from '@/api/base'
	import Qiniu from '@/components/qiniu.vue'
	export default {
		name: "Add",
		components: {
			Qiniu
		},
		props: {
			dialogVisible: {
				type: Boolean,
				default: false
			},
			typeFlag: { // 判断是新建 编辑 详情
				type: String,
				default: 'add'
			},
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				cities: [{
					value: '+',
					label: '增加积分'
				}, {
					value: '-',
					label: '减少积分'
				}],
				value: '',
				// 弹框列表
				catesList: [],
				tagsList: [],
				// 弹框新建
				start_end_time: '',
				cates: [],
				tags: [],
				ruleForm: {
					logo: '',
					gate: '',
					org_name: '',
					org_phone: '',
					city: '',
					org_lng: '',
					org_lat: '',
					douyin_url: '',
					address: '',
					detail: '',
					org_desp: '',
				},
				rules: {
					logo: [
						{ required: true, message: '请上传机构logo', trigger: 'change' }
					],
					gate: [
						{ required: true, message: '请上传大门照片', trigger: 'change' }
					],
					org_name: [
						{ required: true, message: '请输入机构名', trigger: 'blur' }
					],
					org_phone: [
						{ required: true, message: '请输入电话', trigger: 'blur' }
					],
					address: [
						{ required: true, message: '请输入地址', trigger: 'blur' }
					],
					detail: [
						{ required: true, message: '请输入详细地址', trigger: 'blur' }
					]
				},
				// 弹框地图
				map_center: {
					lng: 0,
					lat: 0
				},
				zoom: 20,
				scoreNum: '',
				desp: '',
				rec_Word: '',
			}
		},
		methods: {
			// 确定修改积分
			sure() {
				if (this.typeFlag == 'recWord') {
					recWord({
						org_id: this.id,
						rec_word: this.rec_Word
					}).then(res => {
						if (res.code == 200) {
							this.$emit('close');
							this.$message('修改成功');
							this.$emit('searchList');
							this.rec_word = ''
						} else {
							this.$message(res.msg);
						}
					}).catch(rej => {
						console.log(rej)
					})
				} else {
					score({
						org_id: this.id,
						score:this.value=='+'?(this.scoreNum):('-'+this.scoreNum),
						desp: this.desp
					}).then(res => {
						if (res.code == 200) {
							this.$emit('close');
							this.$message('修改成功');
							this.$emit('searchList');
							this.scoreNum = ''
						} else {
							this.$message(res.msg);
						}
					}).catch(rej => {
						console.log(rej)
					})
				}

			},
			// 获取分类
			getallCate() {
				if(this.catesList.length > 0) return
				allCate({ action: 'save' }).then((res) => {
					this.catesList = res.data
				}).catch((err) => {
					console.log(err)
				})
			},
			// 设置标签
			setTagsList(tags){
				getTags({
					cate_ids: this.cates.join(',')
				}).then((res) => {
					let list = []
					res.data.forEach(item => {
						list = list.concat(item.tag_info)
					})
					this.tagsList = list
					// 因为小程序没有传tags_json,所以只能用name回显
					this.tags = tags ? tags.split(',') : []
				}).catch((err) => {
					console.log(err, 222)
				})
			},
			// 获取标签
			getTagsList() {
				if(this.cates.join(',').length <= 0) { // 如果没有分类 标签和选中标签都为空
					this.tagsList = []
					this.tags = []
					return
				}
				getTags({
					cate_ids: this.cates.join(',')
				}).then((res) => {
					let list = [], tags = []
					res.data.forEach(item => {
						list = list.concat(item.tag_info)
					})
					this.tagsList = list
					// 因为小程序没有传tags_json,所以只能用name回显
					if(tags) { // 如果分类不选 标签也要去掉
						this.tags.forEach((item) => {
							let index = this.tagsList.findIndex(item2 => item2.tag_name == item)
							if (index > -1) {
								tags.push(this.tagsList[index].tag_name)
							}
						})
					}
					this.tags = tags
				}).catch((err) => {
					console.log(err, 222)
				})
			},
			// 获取数据
			getInfo(id) {
				orgInfo({
					org_id: id || this.id
				}).then((res) => {
					for (let item in this.ruleForm) {
						this.ruleForm[item] = res.data[item]
					}
					console.log(this.ruleForm, 1111)
					// 把后端时间转成前端要的格式
					if (res.data.start_time && res.data.end_time) {
						let start_time = res.data.start_time.split(':'),
							end_time = res.data.end_time.split(':')
						this.start_end_time = [
							new Date(2021, 1, 1, start_time[0], start_time[1], start_time[2]),
							new Date(2021, 1, 1, end_time[0], end_time[1], end_time[2])
						]
					}
					// 分类和标签啦我们是
					if (res.data.cates_json) {
						this.cates = JSON.parse(res.data.cates_json).map(item => Number(item.id))
						this.getallCate()
						this.setTagsList(res.data.tags)
					} else {
						this.cates = []
					}
					// this.tags = JSON.parse(res.data.tags_json).map(item=>item.tag_id).split(',')
				}).catch((err) => {
					console.log(err, 222)
				})
			},
			// 创建
            submitForm(formName){
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.save()
					} else {
						return false;
					}
				});
			},
			save(){
				if(this.cates.length<=0) { // 分类单独判断
					this.$message({
						message: '请先选择分类',
						type: 'warning'
					});
					return
				}
				// start_end_time // 营业时间
				let params = { ...this.ruleForm }
				if (this.typeFlag == 'edit') { params.org_id = this.id }
				// 时间转后端要的格式
				if (this.start_end_time != '') {
					let start = this.start_end_time[0],
						end = this.start_end_time[1]
					params.start_time = start.getHours() + ':' + start.getMinutes() + ':' + start.getSeconds()
					params.end_time = end.getHours() + ':' + end.getMinutes() + ':' + end.getSeconds()
				}
				// 分类和标签转成后端要的格式
				let cates = [],
					cates_json = []
				let tags = [],
					tags_json = []
				if (this.cates.length > 0) {
					this.cates.forEach((item) => {
						let index = this.catesList.findIndex(item2 => item2.id == item)
						if (index > -1) {
							cates_json.push(this.catesList[index])
							cates.push(this.catesList[index].name)
						}
					})
					params.cates = cates
					params.cates_json = cates_json
				}
				if (this.tags.length > 0) {
					this.tags.forEach((item) => {
						let index = this.tagsList.findIndex(item2 => item2.tag_name == item)
						if (index > -1) {
							tags_json.push(this.tagsList[index])
							tags.push(this.tagsList[index].tag_name)
						}
					})
					params.tags = tags
					params.tags_json = tags_json
				}
				// 保存
				saveOrg(params).then((res) => {
					this.$message({
						message: res.msg,
						type: res.code != 200 ? 'warning' : 'success'
					});
					if (res.code == 200) {
						this.close();
						this.$emit('searchList');
					}
				}).catch((err) => {
					console.log(err)
					this.close();
				})
			},
			// 关闭
			close() {
				this.start_end_time = ''
				this.cates = []
				this.tags = []
				this.tagsList = [] // 标签列表
				this.ruleForm = {
					logo: '',
					gate: '',
					org_name: '',
					org_phone: '',
					city: '',
					org_lng: '',
					org_lat: '',
					douyin_url: '',
					address: '',
					detail: '',
					org_desp: '',
				}
				this.$emit('close');
			},
			// 选择图片成功
			uploadSuccess(e) {
				console.log('上传成功接收', e)
				this.ruleForm.logo = this.$constant.qnUrl + '/' + e.key
			},
			// 选择图片成功
			uploadSuccess2(e) {
				this.ruleForm.gate = this.$constant.qnUrl + '/' + e.key
			},
			/**
			 * 地图
			 *  */
			handler({
				BMap
			}) { // 地图加载完成事件, map
				this.BMap = BMap;
				this.getCurlocation();
			},
			getClickInfo(e) { // 点击选中具体位置
				console.log('地理位置', e)
				this.ruleForm.city = e.target.bh;
				this.ruleForm.org_lng = e.point.lng;
				this.ruleForm.org_lat = e.point.lat;
				this.zoom = 22;
			},
			changeAfter() { //e
			},
			getCurlocation() { // 获取浏览器当前定位
				navigator.geolocation.getCurrentPosition((position) => {
					let timestamp = position.timestamp;
					let coords = position.coords;
					console.log(timestamp);
					console.log(coords);
				});
				if (!this.BMap) return false;
				let BMap = this.BMap;
				let geolocation = new BMap.Geolocation();
				let _this = this;

				geolocation.getCurrentPosition(function(r) {
					if (_this.typeFlag == 'add') {
						_this.map_center.lng = r.point.lng;
						_this.map_center.lat = r.point.lat;
						_this.ruleForm.org_lng = r.point.lng;
						_this.ruleForm.org_lat = r.point.lat;
					}
				});
			},
			// 上传图片成功
			imgUpSuccess(res) {
				this.ruleForm.logo = this.$constant.qnUrl + '/' + res.key
			},
		}
	};
</script>
<style lang="less" scoped>
	.input {
		width: 200px;
		margin-right: 10px;

	}
</style>
