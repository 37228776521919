<template>
	<div class="wrapper" v-loading="loading">
		<div class="top-btn" ref="topBtn">
			<div>
				<el-select class="w110" v-model="search.search_gist" filterable placeholder="请选择" size="small">
					<el-option v-for="it in searchGist" :key="it.value" :label="it.label" :value="it.value"> </el-option>
				</el-select>
				<el-input class="w140" size="small" placeholder="请输入内容" v-model="search.desp" clearable> </el-input>
				<el-button size="small" type="primary" @click="searchList(search)">搜索</el-button>
			</div>
			<div>
				<el-select class="w110" v-model="search.status" clearable placeholder="请选择" size="small" @change="searchList(search)">
					<el-option :key="1" label="上架" :value="1"> </el-option>
					<el-option :key="0" label="未启用" :value="0"> </el-option>
					<el-option :key="2" label="下架" :value="2"> </el-option>
				</el-select>
				<el-button size="small" type="primary" @click="add()">添加</el-button>
			</div>
		</div>
		<el-table :data="list" :max-height="tableHeight" :header-cell-style="{background:'#f5f7fa'}">
			<el-table-column prop="org_id" label="id"></el-table-column>
			<el-table-column prop="org_name" label="机构名称"></el-table-column>
			<el-table-column label="机构logo">
				<template slot-scope="scope">
					<img :src="scope.row.logo || ''" style="width: 30px" alt="" />
				</template>
			</el-table-column>
			<!-- <el-table-column prop="img_size" label="logo大小"></el-table-column>
			<el-table-column prop="picture_size" label="logo格式"></el-table-column> -->
			<el-table-column prop="rec_word" label="推荐词"></el-table-column>
			<el-table-column prop="member_name" label="创建人"></el-table-column>
			<el-table-column prop="cates" label="分类"></el-table-column>
			<el-table-column label="机构城市">
				<template slot-scope="scope">
					{{ scope.row.province }} &nbsp;&nbsp; {{ scope.row.city }}
				</template>
			</el-table-column>
			<el-table-column prop="org_phone" label="电话"></el-table-column>
			<el-table-column prop="address" label="地址" show-overflow-tooltip>
				<template slot-scope="scope">
					{{ scope.row.address }} &nbsp;&nbsp; {{ scope.row.detail }}
				</template>
			</el-table-column>
			<el-table-column prop="course_count" label="试听课数量"></el-table-column>
			<el-table-column prop="video_count" label="试听课视频数量"></el-table-column>
			<el-table-column prop="ad_score" label="广告积分"></el-table-column>
			<el-table-column label="数据汇总">
				<template slot-scope="scope">
					已售{{ scope.row.sale_num || '--' }} &nbsp;&nbsp;
					分享{{ scope.row.share_num || '--' }} &nbsp;&nbsp;
					点赞{{ scope.row.vote_num || '--' }} &nbsp;&nbsp;
					问答{{ scope.row.q_a_num || '--' }} &nbsp;&nbsp;
				</template>
			</el-table-column>
			<el-table-column label="状态">
				<template slot-scope="scope">
					{{ scope.row.status == 0 ? '未启用' : 
                   scope.row.status == 1 ? '上架' : 
                   scope.row.status == 2 ? '下架' : '--' }}
				</template>
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-dropdown @command="handleCommand">
						<span class="el-dropdown-link">
							操作<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item :command="{fun: 'handleUp',row: scope.row}">{{ scope.row.status != 1 ? '上架' : '下架' }}</el-dropdown-item>
							<el-dropdown-item :command="{fun: 'handleEdit',row: scope.row.org_id}">编辑</el-dropdown-item>
							<el-dropdown-item :command="{fun: 'handleDetail',row: scope.row.org_id}">详情</el-dropdown-item>
							<el-dropdown-item :command="{fun: 'handleDelete',row: scope.row.org_id}">删除</el-dropdown-item>
							<el-dropdown-item :command="{fun: 'recWord',row: scope.row.org_id}">修改推荐词</el-dropdown-item>
							<el-dropdown-item :command="{fun: 'score',row: scope.row.org_id}">增减广告积分</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange"
		:current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
		</el-pagination>
		<Add ref="add" :dialogVisible="dialogVisible" :typeFlag="typeFlag" :id="id" @close="close" @searchList="searchList"></Add>
	</div>
</template>
<script>
	import { orgList, delOrg, onOrOffOrg } from '@/api/institutional'
	import Add from './component/add.vue'
	export default {
		name: "List",
		data() {
			return {
				loading: true,
				tableHeight: this.$constant.maxHeight,
				// 列表
				searchGist:  [{
					value: 'org_id',
					label: '机构id'
				}, {
					value: 'org_name',
					label: '机构名'
				}, {
					value: 'org_phone',
					label: '手机号'
				}],
				search: {
					search_gist: 'org_name',
					desp: '',
					status: ''
				},
				total: 0,
				limit: 15,
				currentPage: 1,
				list: [],
				// 弹框
				dialogVisible: false,
				id: 0,
				typeFlag: 'add'
			}
		},
		components: {
			Add
		},
		mounted() {
			this.getList()
			this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			window.onresize = this.$common.debounce(()=>{
				this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			}, 1000)
		},
		methods: {
			// 操作回调
			handleCommand(command) {
				this[command.fun](command.row)
			},
			handleDetail(orgId) {
				this.$router.push({
					path:'/institutional/org_detail',
					query: {
						group: 3,
						item: '3-1',
						orgId
					}
				})
			},
			// 修改推荐语
			recWord(id) {
				this.typeFlag = 'recWord'
				this.dialogVisible = true
				this.id = id
			},
			// 获取列表
			getList() {
				orgList({
					limit: this.limit,
					page: this.currentPage,
					status: this.search.status,
					org_id: this.search.search_gist == 'org_id' ? this.search.desp : null,
					org_name: this.search.search_gist == 'org_name' ? this.search.desp : null,
					org_phone: this.search.search_gist == 'org_phone' ? this.search.desp : null,
				}).then((res) => {
					this.total = res.data.total;
					this.list = res.data.data;
					this.loading = false;
				}).catch((err) => {
					this.loading = false;
					console.log(err, 222)
				})
			},
			// 搜索
			searchList(search) {
				this.loading = true
				if(search) this.currentPage = 1;
				this.getList();
			},
			// 页码修改
			handleCurrentChange(val) {
				this.loading = true
				this.currentPage = val;
				this.getList();
			},
			orgVideoACourse(path, orgId) {
				this.$router.push({ 
					path,
					query: { group: 3, item: '3-1', orgId }
				})
			},
			// 新建
			add() {
				this.$refs.add.getallCate()
				this.typeFlag = 'add'
				this.dialogVisible = true
			},
			// 编辑
			handleEdit(id) {
				this.$refs.add.getInfo(id)
				this.id = id
				this.typeFlag = 'edit'
				this.dialogVisible = true
			},
			// 删除
			handleDelete(id) {
				this.$alert('是否确定删除', '提示', {
					confirmButtonText: '确定',
					callback: action => {
						if (action == 'confirm') {
							this.deleteFun(id)
						}
					}
				});
			},
			score(id) {
				this.typeFlag = 'score'
				this.dialogVisible = true
				this.id = id
			},
			// 删除
			deleteFun(id) {
				delOrg({
					org_id: id
				}).then(() => {
					this.$message({
						message: '删除成功',
						type: 'success'
					});
					this.searchList(this.search)
				}).catch((err) => {
					console.log(err)
				})
			},
			// 上下架
			handleUp(row) {
				let _this = this
				let content = row.status != 1 ? '上架' : '下架'
				this.$alert(`是否确定${content}`, '提示', {
					confirmButtonText: '确定',
					callback: action => {
						console.log(action)
						if (action == 'confirm') {
							onOrOffOrg({
								org_id: row.org_id,
								status: row.status == 1 ? 2 : 1
							}).then(() => {
								_this.$message({
									message: row.status != 1 ? '上架成功' : '下架成功',
									type: 'success'
								});
								_this.searchList()
							}).catch((err) => {
								console.log(err)
							})
						}
					}
				});
			},
			// 关闭弹框
			close() {
				this.dialogVisible = false
			},
		}
	};
</script>
<style lang="less" scoped>
</style>
